import React from 'react';

export default function Footer () {
	return (
		<footer className="footer">
			<div style={{padding: "0px 15px"}}>
    			&copy; {new Date().getFullYear()} Krithika Ravishankar. All rights reserved.
				<br/>
				Built with React.js and lots of love!
				Last updated on September 10, 2024.
      		</div>
    	</footer>
	);
}
